import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface SpinnerProps {
  style?: React.CSSProperties;
}

const Spinner: React.FC<SpinnerProps> = ({ style }) => {
  return <CircularProgress style={style} />;
};

export default Spinner;
