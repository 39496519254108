import { IUser, UserDetails } from "types/userSettings";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getUserInfo } from "../../api/authRquests";
import { IEvent } from "../../types/events"
import {StorageItems} from "../../constants/storageItems"

const initialState: IUser = {
  details: JSON.parse(sessionStorage.getItem(StorageItems.ORGANIZER_DETAILS)),
  controlledEvent: JSON.parse(sessionStorage.getItem("controlledEvent")),
  error: null,
  loading: false,
};

export const getUserDetails = createAsyncThunk<UserDetails>("user/getUserDetails", async () => {
  try {
    const { data } = await getUserInfo();
    sessionStorage.setItem(StorageItems.ORGANIZER_DETAILS, JSON.stringify(data));
    return data;
  } catch (err) {
    return err as AxiosError;
  }
});

export const refreshUserDetails = createAsyncThunk(
  "user/refreshUserDetails",
  async (_, { dispatch }) => {
    try {
      sessionStorage.removeItem(StorageItems.ORGANIZER_DETAILS);
      
      const { data } = await getUserInfo();
      
      sessionStorage.setItem(StorageItems.ORGANIZER_DETAILS, JSON.stringify(data));
      
      return data;
    } catch (err) {
      return err as AxiosError;
    }
  }
);

export const setVerificationRequestSent = createAsyncThunk(
  "user/setVerificationRequestSent",
  async (orgId: string | number, { dispatch }) => {
    try {
      localStorage.setItem(`org_${orgId}_verification_requested`, 'true');
      
      dispatch(refreshUserDetails());
      
      return true;
    } catch (err) {
      return false;
    }
  }
);

export const confirmVerificationApproval = createAsyncThunk(
  "user/confirmVerificationApproval",
  async (orgId: string | number, { dispatch }) => {
    try {
      localStorage.removeItem(`org_${orgId}_verification_requested`);
      localStorage.removeItem(`org_${orgId}_needs_acknowledgment`);
      
      dispatch(refreshUserDetails());
      
      return true;
    } catch (err) {
      return false;
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails(state, { payload }: PayloadAction<UserDetails>) {
      state.details = payload;
    },
    setControlledEvent: (state, action: PayloadAction<IEvent | null>) => {
      state.controlledEvent = action.payload;
      if (action.payload) {
        sessionStorage.setItem("controlledEvent", JSON.stringify(action.payload));
      } else {
        sessionStorage.removeItem("controlledEvent");
      }
    },
    updateControlledEvent(state, { payload }: PayloadAction<Partial<IEvent>>) {
      state.controlledEvent = {
        ...state.controlledEvent,
        ...payload,
      };
      sessionStorage.setItem("controlledEvent", JSON.stringify(state.controlledEvent));
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.fulfilled, (state, { payload }: PayloadAction<UserDetails>) => {
      state.details = payload;
      state.loading = false;
    });
    builder.addCase(getUserDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getUserDetails.rejected, (state) => {
      state.loading = false;
    });
  },
});

const { reducer, actions } = userSlice;

export const { setUserDetails, setControlledEvent, updateControlledEvent } = actions;

export const eventsSelector = (state: { userStore: UserDetails }) => state.userStore;

export default reducer;
