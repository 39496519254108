import React, { useEffect } from "react";
import PublicRoutes from "routes/PublicRoutes";
import App from "./App";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import { Notification } from "./components/Notification";
import { clearMessage } from "./features/user/messageSlice";
import { StorageItems } from "./constants/storageItems";
import Cookies from "js-cookie";
import { ThemeProvider } from "@mui/material/styles";
import { useMaterialUIController } from "context";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import CssBaseline from "@mui/material/CssBaseline";

const Public = () => {
  const { message, type } = useAppSelector((state) => state.message);
  const userToken = Cookies.get(StorageItems.TOKEN);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dispatch = useAppDispatch();
  // Display global notification
  useEffect(() => {
    if (message) {
      Notification({
        content: message,
        type,
      });
      dispatch(clearMessage());
    }
  }, [message]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {userToken ? <App /> : <PublicRoutes />}
    </ThemeProvider>
  );
};

export default Public;
