import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ILoginPayload, IRegisterPayload } from "types/auth";
import { login, register } from "../../api/authRquests";
import { setMessage } from "./messageSlice";
import { AppDispatch, AppThunk } from "../../store";
import { getUserDetails } from "./userSlice";
import { StorageItems } from "../../constants/storageItems";
import Cookies from "js-cookie";
import { COOKIE_DOMAIN } from "../../config/env-constants";
import { resetMaterialUIController } from "context";

interface AuthState {
  isLoggedIn: boolean;
  loading: boolean;
  error: string;
}

// Define initial state before the slice
const initialState: AuthState = {
  isLoggedIn: false,
  loading: false,
  error: "",
};

interface ILoginResponse {
  jwt: string;
}

export const loginUser = createAsyncThunk<
  ILoginResponse,
  ILoginPayload,
  { rejectValue: AxiosError }
>("auth/login", async (credentials: ILoginPayload, { rejectWithValue, dispatch }) => {
  try {
    const response = await login(credentials);
    const { status, data } = response;
    const token = data.jwt;
    if (status === 200) {
      localStorage.setItem(StorageItems.TOKEN, token);
    }
    await dispatch(getUserDetails());
    dispatch(
      setMessage({
        type: "success",
        message: "You have successfully authenticated !",
      })
    );
  } catch (err) {
    const error = err as AxiosError;
    dispatch(
      setMessage({
        type: "error",
        message: "There was an error while logging in ! Please try again !",
      })
    );
    return rejectWithValue(error);
  }
});

export const registerUser = createAsyncThunk<
  IRegisterPayload,
  IRegisterPayload,
  { rejectValue: AxiosError }
>("auth/register", async (registrationData: IRegisterPayload, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await register(registrationData);
    dispatch(
      setMessage({
        type: "success",
        message: "You have successfully registered !",
      })
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;
    dispatch(
      setMessage({
        type: "error",
        message: error.message,
      })
    );
    return rejectWithValue(error);
  }
});

// First, explicitly define the action type
const LOGOUT = 'auth/logout';

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },

    clearState: (state) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(loginUser.fulfilled, (state) => {
      state.loading = false;
      state.isLoggedIn = true;
    });
    builder.addCase(loginUser.rejected, (state, { payload }: PayloadAction<AxiosError>) => {
      state.loading = false;
      state.error = payload.message;
    });
  },
});

const { actions, reducer } = authSlice;

export const { setLoading, setErrors, clearState } = actions;

export const authSelector = (state: { authStore: AuthState }) => state.authStore;

export const logoutUser = () => async (dispatch: AppDispatch) => {
  try {
    // 1. Clear cookies with all possible domain variations
    const cookieOptions = {
      path: '/',
      sameSite: 'none' as const,
      secure: true
    };

    // Try all possible domain variations
    [
      '.unde.io',
      'crm.dev.unde.io',
      'dev.unde.io',
      'crm.unde.io',
      'unde.io',
      undefined // local development
    ].forEach(domain => {
      Cookies.remove(StorageItems.TOKEN, { 
        ...cookieOptions,
        domain 
      });
    });

    // 2. Clear all storage
    sessionStorage.clear();
    localStorage.clear();

    // 3. Dispatch Redux action
    dispatch(clearState());

    // 4. Construct redirect URL properly
    const origin = window.location.origin;

    // Wait a bit to ensure all cleanup is done
    await new Promise(resolve => setTimeout(resolve, 100));

    // 5. Force a clean reload to the login page
    if (origin.includes('crm.dev.unde.io')) {
      // For dev server
      window.location.href = 'https://crm.dev.unde.io/authentication/sign-in';
    } else {
      // For local development
      window.location.href = `${origin}/authentication/sign-in`;
    }

  } catch (error) {
    console.error("Logout error:", error);
    
    // Emergency cleanup
    try {
      // Try to remove cookie with all possible domains again
      [
        '.unde.io',
        'crm.dev.unde.io',
        'dev.unde.io',
        undefined
      ].forEach(domain => {
        Cookies.remove(StorageItems.TOKEN, { 
          path: '/',
          domain 
        });
      });

      sessionStorage.clear();
      localStorage.clear();
      dispatch(clearState());

      // Force reload to login page
      if (window.location.origin.includes('crm.dev.unde.io')) {
        window.location.href = 'https://crm.dev.unde.io/authentication/sign-in';
      } else {
        window.location.href = `${window.location.origin}/authentication/sign-in`;
      }
    } catch (e) {
      console.error("Critical error during logout cleanup:", e);
      // Last resort: force reload
      window.location.reload();
    }

    dispatch(
      setMessage({
        type: "error",
        message: "Error during logout. Please refresh the page.",
      })
    );
  }
};

export default reducer;
