import { Navigate } from "react-router-dom"
// @mui icons
import Icon from "@mui/material/Icon"

// Images
import { lazy } from "react"
import { OrganizationRole } from "../constants/organizationRoles"

const AllEvents = lazy(() => import("pages/AllEvents"));
const SubscribersList = lazy(() => import("../pages/SubscribersList"));
const OrdersList = lazy(() => import("../pages/OrdersList"));
const EventDashboard = lazy(() => import("../pages/EventDashboard"));
const DiscountCodes = lazy(() => import("pages/DiscountCodes"));
const Analytics = lazy(() => import("pages/Analytics"));
const TicketList = lazy(() => import("pages/TicketsList"));
const TicketScanner = lazy(() => import("pages/TicketScanner"));
const RegistrationForm = lazy(() => import("pages/RegistrationForm"));
const CreateEvent = lazy(() => import("pages/CreateEvent"));
const EditEvent = lazy(() => import("pages/EditEvent"));
const DiscountCodeDetails = lazy(() => import("pages/DiscountCodeDetailed"));
const OrderDetails = lazy(() => import("pages/OrderDetails"));
const EditConfirmationEmail = lazy(() => import("pages/EditConfirmationEmail/EditConfirmationEmail"));
const SendBulkInvitations = lazy(() => import("pages/SendBulkInvitation/SendBulkInvitations"));


const routes = (eventId: number) => {
  return [
    {
      type: "redirect",
      name: "Organizer Dashboard",
      route: "*",
      component: <Navigate key="redirect" to="/event/dashboard" />,
      roles: [OrganizationRole.ADMIN]
    },
    {
      type: "redirect",
      name: "Operator redirect",
      route: "*",
      component: <Navigate key="redirect" to="scanner" />,
      roles: [OrganizationRole.OPERATOR]
    },
    {
      type: "collapse",
      key: "back-to-main",
      icon: <Icon fontSize="medium">arrow_back</Icon>,
      name: "Organizer Panel",
      noCollapse: true,
      route: "/organizer/events",
      component: <AllEvents />,
      roles: [OrganizationRole.ADMIN, OrganizationRole.OPERATOR]
    },
    { type: "divider", key: "divider-0" },
    {
      type: "collapse",
      name: "Dashboard",
      key: "event-dashboard",
      icon: <Icon fontSize="medium">space_dashboard</Icon>,
      route: "/event/dashboard",
      component: <EventDashboard />,
      noCollapse: true,
      roles: [OrganizationRole.ADMIN]
    },
    {
      type: "collapse",
      name: "Event Settings",
      key: "edit",
      icon: <Icon fontSize="medium">room_preferences</Icon>,
      roles: [OrganizationRole.ADMIN],
      collapse: [
        {
          name: "Edit Event",
          key: "edit-event",
          route: `/edit/${eventId}`,
        },
        {
          name: "Edit Confirmation Email ",
          key: "edit-product",
          route: `/edit/confirmation-email/${eventId}`,
        },
      ],
    },
    {
      type: "collapse",
      name: "Orders",
      key: "event-orders",
      icon: <Icon fontSize="medium">shopping_cart</Icon>,
      route: `/orders/${eventId}`,
      component: <OrdersList />,
      noCollapse: true,
      roles: [OrganizationRole.ADMIN]
    },
    {
      type: "hidden",
      name: "Order Details",
      key: "order-details",
      route: `/orders/${eventId}/detailed/:id`,
      component: <OrderDetails />,
      roles: [OrganizationRole.ADMIN]
    },
    {
      type: "hidden",
      name: "Bulk Invitations",
      key: "bulk-invitations",
      route: `/orders/${eventId}/bulk-invitations/:id`,
      component: <SendBulkInvitations />,
      roles: [OrganizationRole.ADMIN]
    },
    {
      type: "collapse",
      name: "Tickets",
      key: "tickets",
      icon: <Icon fontSize="medium">confirmation_number</Icon>,
      route: "/tickets",
      component: <TicketList />,
      noCollapse: true,
      roles: [OrganizationRole.ADMIN, OrganizationRole.OPERATOR]
    },
    {
      type: "collapse",
      name: "Discount Codes",
      key: "discount-codes",
      icon: <Icon fontSize="medium">local_offer</Icon>,
      route: "/discount-codes",
      component: <DiscountCodes />,
      noCollapse: true,
      roles: [OrganizationRole.ADMIN]
    },
    {
      type: "collapse",
      name: "Analytics",
      key: "event-analytics",
      icon: <Icon fontSize="medium">insights</Icon>,
      route: "/event-analytics",
      disabled: true,
      component: <Analytics />,
      noCollapse: true,
      roles: [OrganizationRole.ADMIN]
    },
    {
      type: "collapse",
      name: "Ticket Scanner",
      key: "tickets-scanner",
      icon: <Icon fontSize="medium">qr_code_scanner</Icon>,
      route: "/scanner",
      component: <TicketScanner />,
      noCollapse: true,
      roles: [OrganizationRole.ADMIN, OrganizationRole.OPERATOR]
    },
    {
      type: "collapse",
      name: "Registration Form",
      key: "registration-form",
      icon: <Icon fontSize="medium">assignment</Icon>,
      route: "/registration-form",
      component: <RegistrationForm />,
      noCollapse: true,
      roles: [OrganizationRole.ADMIN]
    },
    {
      type: "inherit",
      name: "Event",
      key: "event",
      route: "/edit",
      roles: [OrganizationRole.ADMIN],
      inheritances: [
        {
          key: "edit-event-inherit",
          route: "/edit/:id",
          component: <EditEvent />,
        },
        {
          key: "edit-confirmation-email",
          route: "/edit/confirmation-email/:id",
          component: <EditConfirmationEmail />,
        }
      ]
    },
    {
      type: "inherit",
      name: "Discount Codes",
      key: "discount-codes-inherit",
      route: "/discount-codes",
      roles: [OrganizationRole.ADMIN],
      inheritances: [
        {
          key: "discount-codes-create",
          route: "mode",
          component: <DiscountCodeDetails />,
        },
        {
          key: "discount-codes-edit",
          route: "mode/:id",
          component: <DiscountCodeDetails />,
        }
      ]
    },
    {
      type: "inherit",
      name: "Event",
      key: "event",
      route: "/organizer",
      roles: [OrganizationRole.ADMIN],
      inheritances: [
        {
          key: "create-event",
          route: "create",
          component: <CreateEvent />,
        }
      ]
    }
  ];
};

export default routes;
