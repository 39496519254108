import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  isPending,
  isFulfilled,
  isRejected,
  current,
  createAction,
} from "@reduxjs/toolkit";
import {
  getEventById,
  createEvent,
  updateAboutEvent,
  updateEventDetails,
  addEventParticipant,
  removeEventParticipant,
  getAllCategories,
} from "api/eventsRequests";
import {
  addEventTicketType,
  removeEventTicketType,
  updateEventTicketType,
} from "../../api/tickets";
import { AxiosError } from "axios";
import { IEvent, IAboutEvent, IParticipant, IDetails, ITicketType, IEventCategory, ISpeaker, IAgendaItem } from "types/events";
import { RootState } from "../../store";
import { setMessage } from "./messageSlice";
import { ICustomError } from "../../utils/request";
import {
  getEventSpeakers,
  addEventSpeaker,
  updateEventSpeakers,
  updateSpeaker,
  deleteSpeaker,
} from "api/speakersRequests";
import {
  getEventAgendaItems,
  addEventAgendaItem,
  updateEventAgendaItems,
  updateAgendaItem,
  deleteAgendaItem,
} from "api/agendaRequests";

export interface EventState {
  event?: IEvent;
  loading: boolean;
  activeStep: number;
  id?: number;
  error: AxiosError;
  categories: IEventCategory[];
}

interface IUpdateAboutPayload {
  eventData: IAboutEvent;
  eventId: number;
}

interface IUpdateDetailsPayload {
  eventData: IDetails;
  eventId: number;
}

interface IAddParticipantPayload {
  participants: IParticipant[];
  eventId: number;
}

interface IAddTicketTypePayload {
  ticketType: ITicketType;
  eventId: number;
}

interface IUpdateTicketTypePayload {
  ticketType: ITicketType;
  eventId: number;
  ticketTypeId: number;
}

interface IUpdateTicketTypeSync {
  updatedData: ITicketType;
  index: number;
}

const initialState: EventState = {
  event: {
    id: null,
    coverImgUrl: "",
    description: "",
    endTime: null,
    location: null,
    participants: [],
    startTime: null,
    ticketCoverImgUrl: "",
    title: "",
    ticketTypes: [],
    isPublished: null,
    isFree: false,
    isPrivate: false,
    showRegistrationForm: false,
    isPrintingEnabled: false,
    requestInfoPerTicket: false,
    categories: [],
  },
  loading: false,
  error: null,
  activeStep: 0,
  categories: [],
};

export const getEvent = createAsyncThunk<IEvent, number, { rejectValue: AxiosError }>(
  "event/getEvent",
  async (eventId: number, { rejectWithValue }) => {
    try {
      const { data } = await getEventById(eventId);
      return data;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error);
    }
  }
);

export const createNewEvent = createAsyncThunk<IEvent, number, { rejectValue: AxiosError }>(
  "event/createNewEvent",
  async (organizationId: number, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const { data } = await createEvent(organizationId, state.event.event);
      
      // After creating the event, update its details to set the category
      if (state.event.event.categories?.length > 0) {
        await updateEventDetails(data.id, {
          ...state.event.event,
          categories: state.event.event.categories
        });
      }

      // Save speakers if they exist
      if (state.event.event.speakers?.length > 0) {
        await updateEventSpeakers(data.id, state.event.event.speakers);
      }

      dispatch(
        setMessage({
          type: "success",
          message: "Event was successfully created!",
        })
      );
      dispatch(clearEventState());
      return data;
    } catch (err) {
      const error = err as AxiosError<ICustomError>;
      const errorMessage = error?.response?.data?.message || 
                         error?.response?.data?.subErrors?.[0]?.message || 
                         "There was an error while creating event !";
      dispatch(
        setMessage({
          type: "error",
          message: errorMessage,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateAbout = createAsyncThunk<
  IAboutEvent,
  IUpdateAboutPayload,
  { rejectValue: AxiosError }
>("event/updateAbout", async ({ eventData, eventId }, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await updateAboutEvent(eventId, eventData);
    dispatch(
      setMessage({
        type: "success",
        message: "Event was successfully updated !",
      })
    );
    dispatch(setAbout(eventData));
    return data;
  } catch (err) {
    const error = err as AxiosError;
    dispatch(
      setMessage({
        type: "error",
        message: "There was an error while updating event !",
      })
    );
    return rejectWithValue(error);
  }
});

export const updateDetails = createAsyncThunk<
  IAboutEvent,
  IUpdateDetailsPayload,
  { rejectValue: AxiosError }
>("event/updateDetails", async ({ eventData, eventId }, { rejectWithValue, dispatch }) => {
  console.log('EventSlice - Updating details with:', eventData);
  try {
    const { data } = await updateEventDetails(eventId, eventData);
    console.log('EventSlice - API response:', data);
    dispatch(
      setMessage({
        type: "success",
        message: "Event was successfully updated !",
      })
    );
    dispatch(setDetails(eventData));
    return data;
  } catch (err) {
    console.error('EventSlice - Error updating details:', err);
    const error = err as AxiosError;
    dispatch(
      setMessage({
        type: "error",
        message: "There was an error while creating event !",
      })
    );
    return rejectWithValue(error);
  }
});

export const updateParticipantsAsync = createAsyncThunk<
  IParticipant[],
  IAddParticipantPayload,
  { rejectValue: AxiosError }
>("event/updateParticipants", async ({ participants, eventId }, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await addEventParticipant(eventId, participants);
    dispatch(
      setMessage({
        type: "success",
        message: "Entry was successfully added to lineup !",
      })
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;
    dispatch(
      setMessage({
        type: "error",
        message: "There was an error while updating lineup !",
      })
    );
    return rejectWithValue(error);
  }
});

export const removeParticipantAsync = createAsyncThunk<number, number, { rejectValue: AxiosError }>(
  "event/removeParticipantAsync",
  async (participantId, { rejectWithValue, dispatch }) => {
    try {
      await removeEventParticipant(participantId);
      dispatch(
        setMessage({
          type: "success",
          message: "Entry was successfully removed from lineup !",
        })
      );
      return participantId;
    } catch (err) {
      const error = err as AxiosError;
      dispatch(
        setMessage({
          type: "error",
          message: "There was an error while updating lineup !",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const addAndSaveTicketType = createAsyncThunk<
  ITicketType,
  IAddTicketTypePayload,
  { rejectValue: AxiosError }
>("event/addAndSaveTicketType", async ({ ticketType, eventId }, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await addEventTicketType(eventId, ticketType);
    dispatch(
      setMessage({
        type: "success",
        message: "Ticket type was successfully added !",
      })
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;
    dispatch(
      setMessage({
        type: "error",
        message: "There was an error while updating ticket types !",
      })
    );
    return rejectWithValue(error);
  }
});

export const removeTicketTypeAsync = createAsyncThunk<number, number, { rejectValue: AxiosError }>(
  "event/removeTicketTypeAsync",
  async (ticketTypeId, { rejectWithValue, dispatch }) => {
    try {
      await removeEventTicketType(ticketTypeId);
      dispatch(
        setMessage({
          type: "success",
          message: "Ticket type was successfully removed  !",
        })
      );
      return ticketTypeId;
    } catch (err) {
      const error = err as AxiosError;
      dispatch(
        setMessage({
          type: "error",
          message: "Unable to remove ticket type in use !",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateTicketTypeAsync = createAsyncThunk<
  ITicketType,
  IUpdateTicketTypePayload,
  { rejectValue: AxiosError }
>(
  "event/updateTicketTypeAsync",
  async ({ eventId, ticketType, ticketTypeId }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await updateEventTicketType(eventId, ticketTypeId, ticketType);
      dispatch(
        setMessage({
          type: "success",
          message: "Ticket type was successfully updated !",
        })
      );
      return data;
    } catch (err) {
      const error = err as AxiosError;
      dispatch(
        setMessage({
          type: "error",
          message: "There was an error while updating ticket types !",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const getCategories = createAsyncThunk<IEventCategory[], void, { rejectValue: AxiosError }>(
  "event/getCategories",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getAllCategories();
      return data;
    } catch (err) {
      const error = err as AxiosError;
      dispatch(
        setMessage({
          type: "error",
          message: "Failed to load categories. Please try again.",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const getSpeakersAsync = createAsyncThunk(
  "event/getSpeakers",
  async (eventId: number, { rejectWithValue }) => {
    try {
      const response = await getEventSpeakers(eventId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSpeakerAsync = createAsyncThunk(
  "event/addSpeaker",
  async ({ eventId, speaker }: { eventId: number; speaker: ISpeaker }, { rejectWithValue }) => {
    try {
      const response = await addEventSpeaker(eventId, speaker);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSpeakersAsync = createAsyncThunk(
  "event/updateSpeakers",
  async ({ eventId, speakers }: { eventId: number; speakers: ISpeaker[] }, { rejectWithValue }) => {
    try {
      const response = await updateEventSpeakers(eventId, speakers);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSpeakerAsync = createAsyncThunk(
  "event/updateSpeaker",
  async ({ speakerId, speaker }: { speakerId: number; speaker: ISpeaker }, { rejectWithValue }) => {
    try {
      const response = await updateSpeaker(speakerId, speaker);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSpeakerAsync = createAsyncThunk(
  "event/deleteSpeaker",
  async (speakerId: number, { rejectWithValue }) => {
    try {
      await deleteSpeaker(speakerId);
      return speakerId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAgendaItemsAsync = createAsyncThunk(
  "event/getAgendaItems",
  async (eventId: number, { rejectWithValue }) => {
    try {
      const response = await getEventAgendaItems(eventId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAgendaItemAsync = createAsyncThunk(
  "event/addAgendaItem",
  async ({ eventId, agendaItem }: { eventId: number; agendaItem: IAgendaItem }, { rejectWithValue, dispatch }) => {
    try {
      const response = await addEventAgendaItem(eventId, agendaItem);
      dispatch(
        setMessage({
          type: "success",
          message: "Agenda item was successfully added!",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setMessage({
          type: "error",
          message: "There was an error while adding the agenda item!",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateAgendaItemsAsync = createAsyncThunk(
  "event/updateAgendaItems",
  async ({ eventId, agendaItems }: { eventId: number; agendaItems: IAgendaItem[] }, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateEventAgendaItems(eventId, agendaItems);
      dispatch(
        setMessage({
          type: "success",
          message: "Agenda was successfully updated!",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setMessage({
          type: "error",
          message: "There was an error while updating the agenda!",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateAgendaItemAsync = createAsyncThunk(
  "event/updateAgendaItem",
  async ({ agendaItemId, agendaItem }: { agendaItemId: number; agendaItem: IAgendaItem }, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateAgendaItem(agendaItemId, agendaItem);
      dispatch(
        setMessage({
          type: "success",
          message: "Agenda item was successfully updated!",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setMessage({
          type: "error",
          message: "There was an error while updating the agenda item!",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const deleteAgendaItemAsync = createAsyncThunk(
  "event/deleteAgendaItem",
  async (agendaItemId: number, { rejectWithValue, dispatch }) => {
    try {
      await deleteAgendaItem(agendaItemId);
      dispatch(
        setMessage({
          type: "success",
          message: "Agenda item was successfully deleted!",
        })
      );
      return agendaItemId;
    } catch (error) {
      dispatch(
        setMessage({
          type: "error",
          message: "There was an error while deleting the agenda item!",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const eventsSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setAbout: (state, { payload }: PayloadAction<IAboutEvent>) => {
      state.event = {
        ...state.event,
        ...payload,
      };
    },
    setDetails: (state, { payload }: PayloadAction<IDetails>) => {
      state.event = {
        ...state.event,
        ...payload,
      };
    },
    updateParticipants: (state, { payload }: PayloadAction<IParticipant[]>) => {
      state.event.participants = payload;
    },
    removeParticipant: (state, { payload }: PayloadAction<number>) => {
      const participantsCurrent = current(state).event.participants;
      state.event.participants = participantsCurrent.filter((element, index) => index !== payload);
    },
    addTicketType: (state, { payload }: PayloadAction<ITicketType>) => {
      const { ticketTypes } = state.event;
      state.event.ticketTypes = [...ticketTypes, payload];
    },
    removeTicketType: (state, { payload }: PayloadAction<number>) => {
      const ticketTypesCurrent = current(state).event.ticketTypes;
      state.event.ticketTypes = ticketTypesCurrent.filter((element, index) => index !== payload);
    },
    updateTicketType: (state, { payload }: PayloadAction<IUpdateTicketTypeSync>) => {
      const { ticketTypes } = state.event;
      const { index, updatedData } = payload;
      ticketTypes[index] = updatedData;
    },
    incrementStep: (state) => {
      state.activeStep += 1;
    },
    decrementStep: (state) => {
      if (state.activeStep > 0) {
        state.activeStep -= 1;
        return;
      }
      return state;
    },
    setActiveStep: (state, { payload }: PayloadAction<number>) => {
      state.activeStep = payload;
    },
    setEventPrivacy: (state, { payload }: PayloadAction<boolean>) => {
      state.event.isPrivate = payload;
    },
    clearEventState: () => {
      return initialState;
    },
    setSpeakers: (state, { payload }: PayloadAction<ISpeaker[]>) => {
      state.event.speakers = payload;
    },
    setAgendaItems: (state, { payload }: PayloadAction<IAgendaItem[]>) => {
      state.event.agendaItems = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvent.fulfilled, (state, { payload }) => {
      state.event = payload;
    });
    builder.addCase(updateParticipantsAsync.fulfilled, (state, { payload }) => {
      state.event.participants = payload;
    });
    builder.addCase(addAndSaveTicketType.fulfilled, (state, { payload }) => {
      const { ticketTypes } = state.event;
      state.event.ticketTypes = [...ticketTypes, payload];
    });
    builder.addCase(removeTicketTypeAsync.fulfilled, (state, { payload }) => {
      const { ticketTypes } = state.event;
      state.event.ticketTypes = ticketTypes.filter((element) => element.id !== payload);
    });
    builder.addCase(removeParticipantAsync.fulfilled, (state, { payload }) => {
      const { participants } = state.event;
      state.event.participants = participants.filter((element) => element.id !== payload);
    });
    builder.addCase(updateTicketTypeAsync.fulfilled, (state, { payload }) => {
      const { ticketTypes } = state.event;
      state.event.ticketTypes = ticketTypes.map((element) =>
        element.id === payload.id ? payload : element
      );
    });
    builder.addCase(getCategories.fulfilled, (state, { payload }) => {
      state.categories = payload;
    });
    builder.addCase(getSpeakersAsync.fulfilled, (state, { payload }) => {
      state.event.speakers = payload;
    });
    builder.addCase(addSpeakerAsync.fulfilled, (state, { payload }) => {
      if (!state.event.speakers) {
        state.event.speakers = [];
      }
      state.event.speakers.push(payload);
    });
    builder.addCase(updateSpeakersAsync.fulfilled, (state, { payload }) => {
      state.event.speakers = payload;
    });
    builder.addCase(updateSpeakerAsync.fulfilled, (state, { payload }) => {
      if (state.event.speakers) {
        state.event.speakers = state.event.speakers.map(speaker => 
          speaker.id === payload.id ? payload : speaker
        );
      }
    });
    builder.addCase(deleteSpeakerAsync.fulfilled, (state, { payload }) => {
      if (state.event.speakers) {
        state.event.speakers = state.event.speakers.filter(speaker => speaker.id !== payload);
      }
    });
    builder.addCase(getAgendaItemsAsync.fulfilled, (state, { payload }) => {
      state.event.agendaItems = payload;
    });
    builder.addCase(addAgendaItemAsync.fulfilled, (state, { payload }) => {
      if (!state.event.agendaItems) {
        state.event.agendaItems = [];
      }
      state.event.agendaItems.push(payload);
    });
    builder.addCase(updateAgendaItemsAsync.fulfilled, (state, { payload }) => {
      state.event.agendaItems = payload;
    });
    builder.addCase(updateAgendaItemAsync.fulfilled, (state, { payload }) => {
      if (state.event.agendaItems) {
        state.event.agendaItems = state.event.agendaItems.map(item => 
          item.id === payload.id ? payload : item
        );
      }
    });
    builder.addCase(deleteAgendaItemAsync.fulfilled, (state, { payload }) => {
      if (state.event.agendaItems) {
        state.event.agendaItems = state.event.agendaItems.filter(item => item.id !== payload);
      }
    });
    builder.addMatcher(isPending, (state, action) => {
      const isUpdatingParticipants = action.type.includes("updateParticipants");
      if (!isUpdatingParticipants) {
        state.loading = true;
      }
    });
    builder.addMatcher(isFulfilled, (state) => {
      state.loading = false;
    });
    builder.addMatcher(isRejected, (state) => {
      state.loading = false;
    });
  },
});

const { actions, reducer } = eventsSlice;

export const {
  setAbout,
  setDetails,
  addTicketType,
  updateTicketType,
  updateParticipants,
  removeParticipant,
  removeTicketType,
  incrementStep,
  decrementStep,
  setActiveStep,
  setEventPrivacy,
  clearEventState,
  setSpeakers,
  setAgendaItems,
} = actions;

export const eventSelector = (state: { eventStore: EventState }) => state.eventStore;

export default reducer;
