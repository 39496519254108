import { IAgendaItem } from "types/events";
import request from "../utils/request";

export const getEventAgendaItems = (eventId: number) => {
  return request({
    url: `/api/events/${eventId}/agenda`,
    method: "GET",
  });
};

export const addEventAgendaItem = (eventId: number, agendaItem: IAgendaItem) => {
  return request({
    url: `/api/events/${eventId}/agenda`,
    method: "POST",
    data: agendaItem,
  });
};

export const updateEventAgendaItems = (eventId: number, agendaItems: IAgendaItem[]) => {
  return request({
    url: `/api/events/${eventId}/agenda`,
    method: "PUT",
    data: agendaItems,
  });
};

export const updateAgendaItem = (agendaItemId: number, agendaItem: IAgendaItem) => {
  return request({
    url: `/api/events/agenda/${agendaItemId}`,
    method: "PUT",
    data: agendaItem,
  });
};

export const deleteAgendaItem = (agendaItemId: number) => {
  return request({
    url: `/api/events/agenda/${agendaItemId}`,
    method: "DELETE",
  });
}; 