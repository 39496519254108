import React, { lazy, Suspense } from "react";
import Spinner from "../components/Spinner";
import { Navigate, Route, Routes } from "react-router-dom";
import themeDark from "../assets/theme-dark";
import theme from "../assets/theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { useMaterialUIController } from "../context";

//Routes
const SignIn = lazy(() => import("pages/Auth/SigninRedirect"));
// const SignUp = lazy(() => import ('layouts/authentication/SignUp'))

const PublicRoutes = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
          <Route path="/authentication/sign-in" element={<SignIn />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
};

export default PublicRoutes;
