import { ISpeaker } from "types/events";
import request from "../utils/request";

export const getEventSpeakers = (eventId: number) => {
  return request({
    url: `/api/events/${eventId}/speakers`,
    method: "GET",
  });
};

export const addEventSpeaker = (eventId: number, speaker: ISpeaker) => {
  return request({
    url: `/api/events/${eventId}/speakers`,
    method: "POST",
    data: speaker,
  });
};

export const updateEventSpeakers = (eventId: number, speakers: ISpeaker[]) => {
  return request({
    url: `/api/events/${eventId}/speakers`,
    method: "PUT",
    data: speakers,
  });
};

export const updateSpeaker = (speakerId: number, speaker: ISpeaker) => {
  return request({
    url: `/api/events/speakers/${speakerId}`,
    method: "PUT",
    data: speaker,
  });
};

export const deleteSpeaker = (speakerId: number) => {
  return request({
    url: `/api/events/speakers/${speakerId}`,
    method: "DELETE",
  });
}; 