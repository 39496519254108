import { configureStore, combineReducers, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "@reduxjs/toolkit";
import subscribers from "features/user/subscribersSlice";
import subscriber from "features/user/subscriberSlice";
import events from "features/user/eventsSlice";
import auth from "features/user/authSlice";
import message from "features/user/messageSlice";
import user from "features/user/userSlice";
import event from "features/user/eventSlice";
import coupons from "features/user/couponsSlice";
// import logger from 'redux-logger';

const rootReducer = combineReducers({
  subscribers,
  subscriber,
  events,
  auth,
  message,
  user,
  event,
  coupons,
});

// Create the store instance immediately instead of returning a function
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
